

class Dispatcher {
	constructor() {
		this.listeners = {};
	}
	
	addEventListener(eventName, fn) {
		if (!this.listeners.hasOwnProperty(eventName))
			this.listeners[eventName] = [];
		this.removeEventListener(eventName, fn); // Just in case it already exists
		this.listeners[eventName].push(fn);
	}
	
	removeEventListener(eventName, fn) {
		if (!this.listeners.hasOwnProperty(eventName))
			return;
		for(let i=0; i < this.listeners[eventName].length; i++) {
			if (this.listeners[eventName][i] === fn) {
				this.listeners[eventName].splice(i, 1);
			} else {
				//console.log("Not the function", i);
			}
		}
	}
	
	call(eventName, params) {
		if(this.listeners.hasOwnProperty(eventName))
			for(let i=0; i < this.listeners[eventName].length; i++) {
				this.listeners[eventName][i].apply(null, params);
			}
	}
	
}

export default Dispatcher;