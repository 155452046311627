import React, { Component } from 'react';
import PagePadded from '../components/pagepadded.js';
import RequireLogin from '../components/loginrequired.js';
import { toast } from 'react-toastify';
import LinkedEmails from '../components/linkedemails.js';
import api from '../api/index.js';

class Profile extends Component {
	constructor(props) {
		super(props);
		this.saveProfile = this.saveProfile.bind(this);
		this.savePassword = this.savePassword.bind(this);
		this.logOut = this.logOut.bind(this);
		this.onChange = this.onChange.bind(this);
		this.state = {
			p1: '',
			p2: '',
			reference_string: api.auth.softUserProp('reference_string'),
			location: api.auth.softUserProp('location'),
		};
		//this.loggedOut = this.loggedOut.bind(this);
		this.loggedIn = this.loggedIn.bind(this);
		
	}
	
	onChange(e) {
		this.setState({[e.target.name]: e.target.value});
	}

	saveProfile(event) {
		console.log("Saving profile...");
		event.preventDefault();
		api.auth.saveProfile(this.state.location, this.state.reference_string);
	}
	savePassword(event) {
		console.log("Saving password...");
		event.preventDefault();
		if (this.state.p1 !== this.state.p2) {
			toast.error("Your passwords don't match.");
			return;
		}
		
		if (this.state.p1.length < 3) {
			toast.error("That password is too short.");
			return;
		}
		
		api.auth.updatePassword(this.state.p1);
	}
	
	logOut() {
		console.log("Logging out..");
		api.auth.logout();
	}
	
	loggedIn() {
		this.setState({
			reference_string: api.auth.softUserProp('reference_string'),
			location: api.auth.softUserProp('location'),
		});
	}

	
	componentDidMount() {
		this.setState({
			reference_string: api.auth.softUserProp('reference_string'),
			location: api.auth.softUserProp('location'),
		});
		api.auth.addEventListener('userRetrieved', this.loggedIn);
	}


	componentWillUnmount() {
		api.auth.removeEventListener('userRetrieved', this.loggedIn);
	}
	
	render() {
		return <RequireLogin>
			<div className="profile-page">
				<PagePadded>
					<h1>Profile: <small>{api.auth.getUserProp('first_name')} {api.auth.getUserProp('last_name')}</small></h1>
					<form onSubmit={this.saveProfile}>
						<h4>Location</h4>
						<p>To help your friends know which you is you in the search results.</p>
						<input type="text" name="location" value={this.state.location} placeholder="Location" onChange={this.onChange} />
						<h4>Reference string</h4>
						<p>Another way to help your friends know which you is you in the search results. It can be anything they'll recognize; nicknames work great.</p>
						<input type="text" name="reference_string" value={this.state.reference_string} placeholder="Reference string" onChange={this.onChange} />
						<input type="submit" value="Update your profile" />
					</form>
				</PagePadded>
				<hr />
				<PagePadded>
					<h1>Update your password</h1>
					<form onSubmit={this.savePassword}>
						<input type="password" name="p1" value={this.state.p1} placeholder="Password" onChange={this.onChange} />
						<input type="password" name="p2" value={this.state.p2} placeholder="Password" onChange={this.onChange} />
						<input type="submit" value="Update your password" />
					</form>
				</PagePadded>
				<hr />
				<PagePadded>
					<h1>Linked emails</h1>
					<p className="form-help">In case you have multiple email addresses, you can link them to your account - that way, you can log in using any of them, and your friends can use any of them to find you.</p>
					<LinkedEmails />
				</PagePadded>
				<hr />
				<PagePadded>
					<button onClick={this.logOut} className="red block">Sign out</button>
				</PagePadded>
				
			</div>
		</RequireLogin>;
	}
}

export default Profile;
