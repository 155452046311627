import Dispatcher from './Dispatcher.js';

class Wishlist extends Dispatcher {
	constructor(parent) {
		super();
		this.parent = parent;
	}
	
	
	retrieve(user = null) {
		const url =  (user == null ? 'wishlist' : 'friends/' + user);
		this.parent.get(url)
			.then(
					(result) => {
						this.call('wishlistReceived', [result]);
				}
			).catch(this.parent.catcher);; 
	}
	
	retrieveReserved() {
		const url =  'wishlist/reserve';
		this.parent.get(url)
			.then(
					(result) => {
						this.call('reservedReceived', [result]);
				}
			).catch(this.parent.catcher);; 
	}
	
	retrieveGift(id) {
		const url = 'wishlist/' + id
		this.parent.get(url)
			.then(
					(result) => {
						this.call('giftRetrieved', [result]);
				}
			).catch(this.parent.catcher); 
	}
	
	receiveGift(id) {
		const url = 'wishlist/receive/' + id
		this.parent.get(url)
			.then(
					(result) => {
						this.parent.success("Gift marked as received!");
						this.call('giftReceived', [result]);
						this.retrieve();
				}
			).catch(this.parent.catcher); 
	}

	deleteGift(id) {
		const url = 'wishlist/' + id
		this.parent.del(url)
			.then(
					(result) => {
						this.parent.success("Gift deleted!");
						this.call('giftDeleted', [result]);
						this.retrieve();
				}
			).catch(this.parent.catcher); 
	}

	reserveGift(id) {
		const url = 'wishlist/reserve/' + id
		this.parent.post(url)
			.then(
					(result) => {
						this.parent.success("Gift reserved!");
						this.call('giftReserved', [result]);
				}
			).catch(this.parent.catcher); 
	}

	unreserveGift(id) {
		const url = 'wishlist/reserve/' + id
		this.parent.del(url)
			.then(
					(result) => {
						this.parent.success("Gift unreserved!");
						this.call('giftUnreserved', [result]);
				}
			).catch(this.parent.catcher); 
	}

	
	addGift(name, priority, link, notes) {
		const url = 'wishlist/';
		this.parent.post(url, {
			name: name,
			priority: priority,
			link: link,
			notes: notes
		})
			.then(
					(result) => {
						this.call('giftAdded', [result]);
						this.parent.success("Gift added!");
						this.retrieve();
				}
			).catch(this.parent.catcher); 
	}

	updateGift(id, name, priority, link, notes) {
		const url = 'wishlist/' + id;
		this.parent.put(url, {
			name: name,
			priority: priority,
			link: link,
			notes: notes
		})
			.then(
					(result) => {
						this.call('giftUpdated', [result]);
						this.parent.success("Gift updated!");
						this.retrieve();
				}
			).catch(this.parent.catcher); 
	}

}


export default Wishlist;
