import Auth from "./Auth.js";
import Wishlist from "./Wishlist.js";
import Friends from "./Friends.js";
import Contact from "./Contact.js";
import SecretSanta from "./SecretSanta.js";
import LinkedEmails from "./LinkedEmails.js";


var Api = function() {
	
	this.location = 'https://thefamilywishlist.com/api/';
	
	this.auth = new Auth(this);
	this.wishlist = new Wishlist(this);
	this.friends = new Friends(this);
	this.contact = new Contact(this);
	this.secretsanta = new SecretSanta(this);
	this.emails = new LinkedEmails(this);
	this.errorFn = null;
	this.successFn = null;
	this.lastLoggedOutError = 0;

	
	this.getHeaders = function() {
		let headers = {
			credentials: 'same-origin',
		}
		if (this.auth.sid != null)
			headers['headers'] = {Authorization: 'Bearer ' + this.auth.sid}
			// Add the auth header
		return headers;
	}
	
	this.get = function(endpoint) {
		let headers = this.getHeaders();
		return fetch(this.location + endpoint, headers)
			.then(this.handleErrors)
			.then(res => res.json());

	}
	
	this.post = function(endpoint, params) {
		const data = new FormData();
		for (let prop in params) {
			if (params.hasOwnProperty(prop)) {
				data.append(prop, params[prop]);
			}
		}

		let headers = this.getHeaders();
		headers['method'] = "POST";
		headers['body'] = data;

		return fetch(this.location + endpoint, headers)
			.then(this.handleErrors)
			.then(res => res.json());
	}

	this.put = function(endpoint, params) {
		let headers = this.getHeaders();
		headers['method'] = "PUT";
		headers['body'] = JSON.stringify(params);

		return fetch(this.location + endpoint, headers)
			.then(this.handleErrors)
			.then(res => res.json());
	}


	this.del = function(endpoint, params = {}) {
		let headers = this.getHeaders();
		headers['method'] = "DELETE";
		headers['body'] = JSON.stringify(params);


		return fetch(this.location + endpoint, headers)
			.then(this.handleErrors)
			.then(res => res.json());
	}

	
	this.success = function(msg) {
		if (this.successFn != null)
			this.successFn(msg);
	}.bind(this);
	
	this.catcher = function(err) {
		if (err.message === "Please login") {
			const now = (new Date()).getTime();
			if (now > this.lastLoggedOutError + 5000) {
				this.lastLoggedOutError = now;
			} else {
				return;
			}
		}
		if (this.errorFn != null)
			this.errorFn(err);
	}.bind(this);

	this.handleErrors = async function(response) {
		if (!response.ok) {
			if (response.status === 401) {
				this.auth.call('loggedOut');
				this.auth.deleteCookie();
			}

			// If there was an error response, try to extract something meaningful
			let data = await response.text();
			if (data == null)
				data = response.statusText;
			const e = Error(data);
			try {
				const json_data = JSON.parse(data);
				if (json_data && json_data.hasOwnProperty('description')) {
					e.message = json_data['description'];
				}
			} catch(e) {
				// no-op
			}
			throw e;
		}
		return response;
	}.bind(this);
}


var api = new Api();
window.api = api;
export default api;
