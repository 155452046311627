import React, { Component } from 'react';
import { toast } from 'react-toastify';
import PagePadded from '../components/pagepadded.js';
import api from '../api/index.js';

class ForgottenPassword extends Component {
	constructor(props) {
		super(props);
		this.onSubmit = this.onSubmit.bind(this);
		this.onChange = this.onChange.bind(this);
		this.forgotten = this.forgotten.bind(this);
		this.state = {
			email: '',
			done: false
		};
	}
	
	onChange(e) {
		this.setState({[e.target.name]: e.target.value});
	}

	onSubmit(event) {
		if (this.state.email.includes('@')) {
			api.auth.forgottenPassword(this.state.email);
			api.auth.addEventListener('forgottenPassword', this.forgotten);
		} else 
			toast.error('That doesn\'t appear to be an e-mail address.');
		event.preventDefault();
	}
	

	forgotten(data) {
		api.auth.removeEventListener('forgottenPassword', this.forgotten);
		this.setState({done: true});
	}
	
	componentWillUnmount() {
		api.auth.removeEventListener('forgottenPassword', this.forgotten);
	}

	
	render() {
		if (this.state.done) 
			return <PagePadded><h1>Forgotten password</h1><p>A password reset link has been emailed to you.  Check there for further instructions.</p></PagePadded>;
		return 	<PagePadded>
					<h1>Password reset</h1>
					<form onSubmit={this.onSubmit}>
						<input type="text" name="email" value={this.state.email} placeholder="Email address" onChange={this.onChange} />
						<input type="submit" value="Send me a reset link!" />
					</form></PagePadded>;
	}
}

export default ForgottenPassword;
