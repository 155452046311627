import React, { Component } from 'react';
import PagePadded from '../components/pagepadded.js';

class About extends Component {
	render() {
		return <div className="about-page">
				<PagePadded>
					<h1>About us</h1>
					<p>You have enough stress in your life being forced to pull potential gift ideas from intended recipients all the while liaising with other members of the give-pool — and that's just those you know! </p>
					<p>That's where we come in. <strong>The Family Wishlist</strong> is a web system designed to simplify listing, sharing, and reserving presents, so that you and your family can focus on what's really important.</p>
					<p>&nbsp;</p>
					<p>But there I go making assumptions again. Maybe you don't have enough stress. Maybe you crave that edge-of-control tension; maybe you <em>need it</em>.</p>
					<p>If that's the case, I'm sorry to say that The Family Wishlist may not be for you. At least not for gift-giving, but you don't have to deprive your family of its organized comforts. Sign up now, and share your wish list for them to see!</p>
				</PagePadded>
			</div>;
	}
}

export default About;
