import React, { Component } from 'react';
import PagePadded from '../components/pagepadded.js';
import SearchResult from '../components/searchresult.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import RequireLogin from '../components/loginrequired.js';


import api from '../api/index.js'
import './friendsearch.scss';

class FriendSearch extends Component {
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.searchResults = this.searchResults.bind(this);
		this.invite = this.invite.bind(this);
		
		this.state = {
			query: '',
			results: null
		};
	}
	
	handleChange(e) {
		this.setState({[e.target.name]: e.target.value});
	}

	handleSubmit(event) {
		event.preventDefault();
		api.friends.search(this.state.query);
	}
	
	searchResults(data) {
		this.setState({results: data});
	}
	
	invite() {
		api.friends.addFriendByEmail(this.state.query);
	}
	
	componentDidMount() {
		api.friends.addEventListener('searchResults', this.searchResults);
	}
	
	componentWillUnmount() {
		api.friends.removeEventListener('searchResults', this.searchResults);
	}
	
	render() {
		let results = '';
		if (this.state.results != null) {
			if (this.state.results.length === 0) {
				if (this.state.query.includes("@"))
					results = <PagePadded className="invite">
							<h2>Search results</h2>
							<p>No such user found. If you searched by email address, we can send them an invite – they’ll automatically be added to your friends list should they sign up.</p>
							<p><button onClick={this.invite} className="invite block"><FontAwesomeIcon icon={faEnvelope}/> Send an invite</button></p>
						</PagePadded>;
				else 
					results = <PagePadded>
								<h2>Search results</h2>
								<p>No such user found. If you searched by email address, we can send them an invite – they’ll automatically be added to your friends list should they sign up.</p>
							</PagePadded>;
			} else {
				let i = 0;
				let contents = this.state.results.map(function(a) {
					return <li key={i++}><SearchResult user={a} /></li>;
				});
				results = <div><PagePadded><h2>Search results</h2></PagePadded><ul>{contents}</ul></div>;
			}
			results = <div className="results">{results}</div>;
		}
		return <RequireLogin><div className="friend-search-page">
				<PagePadded>
					<h1>Search</h1>
					<form onSubmit={this.handleSubmit}>
						<input type="text" name="query" placeholder="email or name" onChange={this.handleChange} value={this.state.query} />
						<input type="submit" value="Search" />
					</form>
				</PagePadded>
				<hr />
				{results}
			</div></RequireLogin>;
	}
}

export default FriendSearch;
