import React, { Component } from 'react';
import './pagepadded.scss';

class PagePadded extends Component {
	render() {
		return <div className={"page-padded " + this.props.className}>{this.props.children}</div>;
	}
}

export default PagePadded;
