import React, { Component } from 'react';
import PagePadded from '../components/pagepadded.js';
import Loginrequired from '../components/loginrequired.js';
import Wishlist from "../components/wishlist.js";
import Loading from "../components/loading.js";
import api from '../api/index.js';
import { toast } from 'react-toastify';


class Friend extends Component {
	constructor(props) {
		super(props);
		this.friendReceived = this.friendReceived.bind(this);
		this.state = {
			name: null
		};
		//api.friends.retrieve(this.props.match.params.id); // The friendslist will do this
	}

	friendReceived(f) {
		for(let i=0; i < f.length; i++) {
			if (f[i].user_id === parseInt(this.props.match.params.id)) {
				this.setState({name: f[i].first_name});
				return;
			}
		}
		toast.error("Friend not found.");
	}
	
	componentDidMount() {
		api.friends.addEventListener('friendsRetrieved', this.friendReceived);
		api.friends.retrieve();
	}


	componentWillUnmount() {
		api.wishlist.removeEventListener('friendsRetrieved', this.friendReceived);
	}
	
	componentWillReceiveProps(newProps) {
		const id = newProps.match.params.id;
		if (id === this.props.match.params.id)
			return;
		this.setState({name: null});
		api.friends.retrieve();
	}
	
	render() {
		let inner = <Loading />;
		if (this.state.name != null)
			inner = <div>
						<PagePadded><h1>{this.state.name}'s wishlist!</h1></PagePadded>
						 <Wishlist id={this.props.match.params.id} />
					</div>; 
					
		return <Loginrequired>
			<div className="FriendsWishlist">
				{inner}
			</div>
		</Loginrequired>;
	}
}

export default Friend;
