import { Component } from 'react';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-1093269-5');


class Analytics extends Component {

	constructor(props, context) {
		super(props, context)
		this.log(this.props.location.pathname);
	}
	
	componentWillReceiveProps(newProps) {
		this.log(newProps.location.pathname);
	}
	
	log(pathname) {
		//console.log("analytics", pathname);
		//console.log(window.location.pathname + window.location.search);
		ReactGA.pageview(window.location.pathname + window.location.search);
	}
	
	render() {
		return null;
	}
}

export default Analytics;