import React, { Component } from 'react';
import PagePadded from '../components/pagepadded.js';
import ReCAPTCHA from "react-google-recaptcha";
import { Redirect } from "react-router-dom";
import api from '../api/index.js';

import './contact.scss';

class Contact extends Component {
	constructor(props) {
		super(props);
		this.onSubmit = this.onSubmit.bind(this);
		this.onChange = this.onChange.bind(this);
		this.captchaChange = this.captchaChange.bind(this);
		this.dataLoaded = this.dataLoaded.bind(this);
		this.sent = this.sent.bind(this);
		this.state = {
			subject: 'General Comments',
			name: '',
			email: '',
			website: '',
			comments: '',
			captcha: '',
			sent: false
		};
	}
	
	
	onChange(e) {
		this.setState({[e.target.name]: e.target.value});
	}
	captchaChange(value) {
		this.setState({captcha: value});
	}
	
	onSubmit(event) {
		event.preventDefault();
		api.contact.addEventListener('contactSent', this.sent);
		api.contact.send(this.state.subject, this.state.name, this.state.email, this.state.website, this.state.comments, this.state.captcha);
	}
	
	sent(data) {
		api.contact.removeEventListener('contactSent', this.sent);
		this.setState({sent: true});
	}
	
	componentDidMount() {
		api.auth.addEventListener('userRetrieved', this.dataLoaded);
		this.setState({
			name: (api.auth.softUserProp('first_name') + ' ' + api.auth.softUserProp('last_name')).trim(),
			email: api.auth.softUserProp('email_address'),
		});
	}
	
	dataLoaded(data) {
		this.setState({
			name: (api.auth.softUserProp('first_name') + ' ' + api.auth.softUserProp('last_name')).trim(),
			email: api.auth.softUserProp('email_address'),
		});
	}

	componentWillUnmount() {
		api.contact.removeEventListener('contactSent', this.sent);
		api.auth.removeEventListener('userRetrieved', this.dataLoaded);
	}


	render() {
		if(this.state.sent) 
			return <Redirect to="/" />;
		
		return <PagePadded className="contact">
			<h1>Contact</h1>
			<form onSubmit={this.onSubmit}>
				<select name="subject" value={this.state.subject} onChange={this.onChange}>
					<option value="General Comments">General Comments</option>
					<option value="Bug Report">Bug Report</option>
				</select>
				<input type="text" name="name" value={this.state.name} placeholder="Name" onChange={this.onChange} />
				<input type="text" name="email" value={this.state.email} placeholder="Email" onChange={this.onChange} />
				<input type="text" name="website" value={this.state.website} placeholder="Website" onChange={this.onChange} />
				<textarea name="comments" value={this.state.comments} placeholder="Comments" onChange={this.onChange} />
				<ReCAPTCHA
					sitekey="6LfeXHsUAAAAAMfmkchAQcI6dZpybpMMvDlaCDUZ"
					onChange={this.captchaChange}
				/>
				<p><input type="submit" value="Submit"/></p>
			</form>
		
		</PagePadded>;
	}
}

export default Contact;
