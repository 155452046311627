import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from './components/header.js';
import Footer from './components/footer.js';
import UserBar from './components/userbar.js';
import Friendslist from "./components/friendslist.js";

import './App.scss';

import api from './api/index.js';

import Index from './routes/index.js';
import Friend from './routes/friend.js';
import About from './routes/about.js';
import Faq from './routes/faq.js';
import Contact from './routes/contact.js';
import Secretsanta from './routes/secretsanta.js';
import FriendSearch from './routes/friendsearch.js';
import Profile from './routes/profile.js';
import Register from './routes/register.js';
import ResetPassword from './routes/resetpassword.js';
import ForgottenPassword from './routes/forgottenpassword.js';
import LinkConfirmation from './routes/linkconfirmation.js';
import Analytics from './components/analytics.js';




class App extends Component {
	constructor(props) {
		super(props);
		this.handleResize = this.handleResize.bind(this);
		this.state = {
			size: this.getSize(),
			navopen: false,
			friendsopen: false
		};
		this.toastErrors = this.toastErrors.bind(this);
		this.toastSuccess = this.toastSuccess.bind(this);
		this.navOpen = this.navOpen.bind(this);
		this.navClose = this.navClose.bind(this);
		this.friendsListToggle = this.friendsListToggle.bind(this);
	}

	componentWillMount() {
		window.addEventListener('resize', this.handleResize);
		api.errorFn = this.toastErrors;
		api.successFn = this.toastSuccess;
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
	}
	
	handleResize() {
		this.setState({ size: this.getSize()});
	}
	
	getSize() {
		return window.innerWidth > 998 ? "desktop" : "mobile";
	}

	toastErrors(e) {
		console.log("toastErrors", e);
		toast.error(e.message);
	}
	
	toastSuccess(msg) {
		toast.success(msg);
	}
	
	navOpen() {
		this.setState({navopen: true});
	}
	navClose() {
		this.setState({navopen: false});
	}
	
	friendsListToggle(isOpen) {
		this.setState({friendsopen: isOpen});
	}
	
	render() {
		return (
			<div className={"App " + this.state.size + (this.state.navopen ? " navopen" : " navclosed")  + (this.state.friendsopen ? " friendsopen" : " ")}>
				<Router>
					<div>
						<Friendslist onChange={this.friendsListToggle} />
						<div className="app-inner">
							<Header onNavOpen={this.navOpen} onNavClose={this.navClose} />
							<UserBar />
							<div className="page routes">
								<Route path="/" exact component={Index} />
								<Route path="/friend/:id"  component={Friend} />
								<Route path="/about" exact component={About} />
								<Route path="/faq" exact component={Faq} />
								<Route path="/contact" exact component={Contact} />
								<Route path="/secretsanta" exact component={Secretsanta} />
								<Route path="/add-friend"  exact component={FriendSearch} />
								<Route path="/profile"  exact component={Profile} />
								<Route path="/register"  exact component={Register} />
								<Route path="/reset-password/:id"  component={ResetPassword} />
								<Route path="/forgotten-password"  exact component={ForgottenPassword} />
								<Route path="/link/:id"  component={LinkConfirmation} />
							</div>
							<Footer />
						</div>
						<Route component={Analytics} />
					</div>
				</Router>
				 <ToastContainer position={toast.POSITION.BOTTOM_CENTER}/>
			</div>
		);
	}
}

export default App;
