import Dispatcher from './Dispatcher.js';

class Contact extends Dispatcher {
	constructor(parent) {
		super();
		this.parent = parent;
	}
	
	
	send(subject, name, email, website, comments, captchakey) {
		const url = 'contact';
		this.parent.post(url, {
			subject: subject,
			name: name,
			email: email,
			website: website,
			comments: comments,
			captcha: captchakey
		})
			.then(
					(result) => {
						this.call('contactSent', [result]);
						this.parent.success("Your message has been sent on its way.");
						
				}
			).catch(this.parent.catcher); 
		
	}

}


export default Contact;
