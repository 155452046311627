import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserFriends, faWindowClose, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import PagePadded from '../components/pagepadded.js';
import api from '../api/index.js'
import './friendslist.scss';

import Friend from './friend.js';

class Friendslist extends Component {
	constructor(props) {
		super(props);
		this.retrievedFriends = this.retrievedFriends.bind(this);
		this.toggle = this.toggle.bind(this);
		this.state = {
			expanded: false,
			friends: null,
			loggedIn: api.auth.isLoggedIn()
		};
		this.loggedOut = this.loggedOut.bind(this);
		this.loggedIn = this.loggedIn.bind(this);
		
	}

	retrievedFriends(friends) {
		this.setState({friends: friends, loggedIn: true});
	}
	
	loggedIn() { 
		this.setState({loggedIn: true});
		api.friends.retrieve();
	}
	loggedOut() { this.setState({loggedIn: false}); }
	
	toggle() {
		this.props.onChange(!this.state.expanded);
		this.setState({expanded: !this.state.expanded});
	}
	
	componentDidMount() {
		api.auth.addEventListener('loggedOut', this.loggedOut);
		api.auth.addEventListener('loggedIn', this.loggedIn);
		api.friends.addEventListener('friendsRetrieved', this.retrievedFriends);
		this.setState({loggedIn: api.auth.isLoggedIn()});
		if (api.auth.isLoggedIn())
			api.friends.retrieve();
	}


	componentWillUnmount() {
		api.auth.removeEventListener('loggedOut', this.loggedOut);
		api.auth.removeEventListener('loggedIn', this.loggedIn);
		api.friends.removeEventListener('friendsRetrieved', this.retrievedFriends);
	}

	

	
	render() {
		if (this.state.loggedIn === false) {
			return null;
		}
		
		let inner = '';
		if (this.state.friends != null) {
			inner = this.state.friends.map((a) => { 
				return <li key={a.user_id}><Friend user={a} onClick={this.toggle} /></li>;
			});
			inner = <ul>{inner}</ul>;
		}
		
		return <div className={"Friendslist" + (this.state.expanded ? " expanded" : "")}>
				<button className="toggle" onClick={this.toggle}><FontAwesomeIcon icon={faUserFriends} className="open"/><FontAwesomeIcon icon={faWindowClose} className="close"/></button>
				<PagePadded><h1>My friends <Link to="/add-friend" onClick={this.toggle}><FontAwesomeIcon icon={faPlusCircle}/></Link></h1></PagePadded>
				{inner}
			</div>
		
	}
}

export default Friendslist;
