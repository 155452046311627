import React, { Component } from 'react';
import { Link } from "react-router-dom";

import api from "../api/index.js";
import './login.scss';

class Login extends Component {
	constructor(props) {
		super(props);
		this.loggedOut = this.loggedOut.bind(this);
		this.loggedIn = this.loggedIn.bind(this);
		api.auth.addEventListener('loggedOut', this.loggedOut);
		api.auth.addEventListener('loggedIn', this.loggedIn);
		this.state = {
			loggedIn: false,
			email: '',
			password: ''
		};
		
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.logout = this.logout.bind(this);
	}

	handleChange(e) {
		this.setState({[e.target.name]: e.target.value});
	}

	handleSubmit(event) {
		api.auth.login(this.state.email, this.state.password);
		event.preventDefault();
	}
	
	
	loggedOut(data) {
		this.setState({loggedIn: false});
	}
	
	loggedIn(data) {
		this.setState({loggedIn: true});
	}
	
	componentDidMount() {
		api.auth.isLoggedIn();
	}
	
	logout(e) {
		e.preventDefault();
		api.auth.logout();
	}
	
	componentWillUnmount() {
		api.auth.removeEventListener('loggedOut', this.loggedOut);
		api.auth.removeEventListener('loggedIn', this.loggedIn);
		
	}	
	render() {
		if (this.state.loggedIn) {
			return (<div className="login">Logged in!
					<button onClick={this.logout}>
						logout
					</button>
				</div>);
		} else {
			return <div className="login">
				<form  onSubmit={this.handleSubmit}>
					<input type="text" name="email" placeholder="email address"  value={this.state.email} onChange={this.handleChange} />
					<input type="password" name="password" placeholder=""  value={this.state.password} onChange={this.handleChange}/>
					<input type="submit" value="Sign in" />
					<p className="forgotten"><Link to="/forgotten-password">I've forgotten my password.</Link></p>
				</form>
			</div>
		}
	}
}

export default Login;
