import React, { Component } from 'react';
import PagePadded from '../components/pagepadded.js';
import './footer.scss';

class Footer extends Component {
	render() {
		return <footer className="footer">
			<PagePadded>
				<p>Website by <a href="https://www.alteredeffect.com" target="_blank" rel="noopener">altered effect</a></p>
			</PagePadded>
		</footer>;
	}
}

export default Footer;
