import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'

import api from '../api/index.js'


class SearchResult extends Component {
	constructor(props) {
		super(props);
		this.addFriend = this.addFriend.bind(this);
	}

	addFriend() {
		api.friends.addFriend(this.props.user.user_id);
	}
	

	render() {
		const reference =  (this.props.user.reference_string.length > 0) ? '"' + this.props.user.reference_string + '"' : '';
		return <div className="search-result">
			<h4>
				<div className="text">
					<strong>{this.props.user.first_name} {this.props.user.last_name}</strong><span className="location"> – {this.props.user.location}</span>
				</div>
				<div className="button">
					<button className="clear" onClick={this.addFriend}><FontAwesomeIcon icon={faPlusCircle} /></button>
				</div>
			</h4>
			<p className="reference">
			{reference}
			</p>
		</div>
	}
}

export default SearchResult;
