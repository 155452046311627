import React, { Component } from 'react';
import api from '../api/index.js';
import Gift from './gift.js';
import './wishlist.scss';
import Loading from './loading.js';


class ReservedGifts extends Component {
	constructor(props) {
		super(props);
		this.reservedReceived = this.reservedReceived.bind(this);
		this.refresh = this.refresh.bind(this);
		this.state = {
			gifts: [],
			loaded: false
		};
	}

	componentDidMount() {
		api.wishlist.addEventListener('reservedReceived', this.reservedReceived);
		api.wishlist.retrieveReserved();
		api.wishlist.addEventListener('giftUnreserved', this.refresh);
		api.wishlist.addEventListener('giftReserved', this.refresh);
	}

	reservedReceived(data) {
		this.setState({gifts: data, loaded: true});
	}

	refresh() {
		api.wishlist.retrieveReserved();
	}

	componentWillUnmount() {
		api.wishlist.removeEventListener('reservedReceived', this.reservedReceived);
		api.wishlist.removeEventListener('giftUnreserved', this.refresh);
		api.wishlist.removeEventListener('giftReserved', this.refresh);
	}

	render() {
		let o = this;
		let list = this.state.gifts.map(function(a) {
			return <Gift name={a.name} gift_data={a} recipient={a.first_name + " " + a.last_name}status='reserved' id={a.gift_id} key={"gift" + a.gift_id} priority={a.priority} onEdit={o.props.onEdit} />;
		});

		if (!this.state.loaded)
			list = <Loading />;
		return <div className="reservedgifts">
				{list}
			</div>;
		
	}
}

export default ReservedGifts;
