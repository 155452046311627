import React, { Component } from 'react';
import api from "../api/index.js";
import Home from "./home.js";
import Wishlist from "./wishlist.js";

class Index extends Component {
	constructor(props) {
		super(props);
		this.loggedOut = this.loggedOut.bind(this);
		this.loggedIn = this.loggedIn.bind(this);
		this.state = {
			loggedIn: api.auth.isLoggedIn(),
		};
	}

	loggedOut(data) {
		this.setState({loggedIn: false});
	}
	
	loggedIn(data) {
		this.setState({loggedIn: true});
	}
	
	
	componentDidMount() {
		api.auth.addEventListener('loggedOut', this.loggedOut);
		api.auth.addEventListener('loggedIn', this.loggedIn);
	}
	
	componentWillUnmount() {
		api.auth.removeEventListener('loggedOut', this.loggedOut);
		api.auth.removeEventListener('loggedIn', this.loggedIn);
		
	}
	
	render() {
		if (this.state.loggedIn) {
			return <Wishlist />;
		} else {
			return <Home />;
		}
	}
}

export default Index;
