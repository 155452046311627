import React, { Component } from 'react';
import Logo from '../images/logo.jpg';
import Nav from './nav.js';
import './header.scss';

class Header extends Component {
	render() {
		return <div className="header">
				<Nav onNavOpen={this.props.onNavOpen} onNavClose={this.props.onNavClose} />
				<a href="/"><img src={Logo} title="The Family Wishlist" alt="Logo" /></a>
			</div>
		
	}
}

export default Header;
