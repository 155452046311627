import Dispatcher from './Dispatcher.js';

class LinkedEmails extends Dispatcher {
	constructor(parent) {
		super();
		this.parent = parent;
	}
	
	
	retrieve() {
		let url =  'linkedemails'
		this.parent.get(url)
			.then(
					(result) => {
						this.call('emailsRetrieved', [result]);
				}
			).catch(this.parent.catcher);
	}
	
	
	addEmail(email) {
		const url = 'linkedemails';
		this.parent.post(url, {
			email: email
		})
			.then(
					(result) => {
						this.call('emailAdded', [result]);
						this.parent.success("Email link pending!  Check you email to confirm.");
						this.retrieve();
				}
			).catch(this.parent.catcher); 
	}
	
	
	removeEmail(email) {
		const url = 'linkedemails'
		this.parent.del(url, {email: email})
			.then(
					(result) => {
						this.parent.success("Email unlinked!");
						this.call('emailDeleted', [result]);
						this.retrieve();
				}
			).catch(this.parent.catcher); 
	}
	
	
	confirmEmail(key) {
		this.parent.put('linkedemails', {'key': key})
			.then(
				(result) => {
					this.call('confirmed', [result]);
					this.parent.success("Email address linked!");
			}
		).catch(this.parent.catcher);
	}
}


export default LinkedEmails;
