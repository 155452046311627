import React, { Component } from 'react';

import Email from './email.js';
import Loading from './loading.js';

import api from '../api/index.js';

class LinkedEmails extends Component {
	constructor(props) {
		super(props);
		this.add = this.add.bind(this);
		this.retrieved = this.retrieved.bind(this);
		this.remove = this.remove.bind(this);
		this.onChange = this.onChange.bind(this);
		this.state = {
			value: '',
			emails: null,
		};
	}
	
	onChange(e) {
		this.setState({[e.target.name]: e.target.value});
	}

	add(event) {
		event.preventDefault();
		api.emails.addEmail(this.state.value);
	}
	
	retrieved(data) {
		this.setState({emails: data});
	}
	remove(event) {
		console.log(event);
	}
	

	
	componentDidMount() {
		api.emails.addEventListener('emailsRetrieved', this.retrieved);
		api.emails.retrieve();
	}


	componentWillUnmount() {
		api.emails.removeEventListener('emailsRetrieved', this.retrieved);
	}
	
	render() {
		let emails = <Loading />;
		if (this.state.emails != null)
			emails = this.state.emails.map(function (a) { return <Email key={a} email={a} />;});
		return <div>
				{emails}
					<form onSubmit={this.add}>
						<input type="text" name="value" value={this.state.value} onChange={this.onChange} placeholder="Email to link" />
						<input type="submit" value="Link it" />
					</form>
				</div>;
	}
}

export default LinkedEmails;
