import React, { Component } from 'react';
import LoadingImg from './loading.gif';
import './loading.scss';

class Loading extends Component {
	render() {
		// Switch this to a gif
		return <div className={"DelayedLoad " + this.props.className}>
				<img src={LoadingImg} alt="Loading..." />
			</div>
		
	}
}

export default Loading;
