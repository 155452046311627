import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faUser } from '@fortawesome/free-solid-svg-icons'

import api from '../api/index.js'

import './friend.scss';

class Friend extends Component {
	constructor(props) {
		super(props);
		this.removeFriend = this.removeFriend.bind(this);
	}
	
	removeFriend() {
		api.friends.removeFriend(this.props.user.email_address);
	}
	
	render() {
		return <div className="friend"><Link to={"/friend/" + this.props.user.user_id} onClick={this.props.onClick}><FontAwesomeIcon icon={faUser} />{this.props.user.first_name} {this.props.user.last_name}</Link> <button  className="clear" onClick={this.removeFriend}><FontAwesomeIcon icon={faPlusCircle} className="x" /></button></div>

	}

}

export default Friend;
