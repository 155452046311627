import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import Wishlist from "../components/wishlist.js";
import GiftForm from "../components/giftform.js";
import ReservedGifts from "../components/reservedgifts.js";
import PagePadded from '../components/pagepadded.js';

import api from '../api/index.js';
import './wishlist.scss';

class WishlistPage extends Component {
	constructor(props) {
		super(props);
		this.editGift = this.editGift.bind(this);
		this.newGift = this.newGift.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
		this.saved = this.saved.bind(this);
		this.state = {
			form: false,
			gift_id: null
		};
	}

	editGift(gift_id) {
		this.setState({form: true, gift_id: gift_id});
	}
	
	newGift() {
		this.setState({form: true, gift_id: null});
	}

	handleCancel() {
		this.setState({form: false, gift_id: null});
	}
	
	saved() {
		this.setState({form: false, gift_id: null});
	}
	
	componentDidMount() {
		api.wishlist.addEventListener('giftAdded', this.saved);
		api.wishlist.addEventListener('giftUpdated', this.saved);
	}


	componentWillUnmount() {
		api.wishlist.removeEventListener('giftAdded', this.saved);
		api.wishlist.removeEventListener('giftUpdated', this.saved);
	}
	
	
	render() {
		let inner = this.state.form ? <PagePadded><GiftForm id={this.state.gift_id} onCancel={this.handleCancel} /></PagePadded> : <Wishlist onEdit={this.editGift} />;
		return <div className="MyWishlist">
				<div>
				<PagePadded>
					<h1>
						My wishlist 
						{this.state.form ? '' : <button onClick={this.newGift} className="clear" title="Add a gift"><FontAwesomeIcon icon={faPlusCircle} /></button>}
					</h1>
				</PagePadded>
				{inner}
				<hr className="large" />
				</div>
				<div>
				<PagePadded>
					<h2>Reserved gifts</h2>
				</PagePadded>
				<ReservedGifts />
				</div>
		</div>
		
	}
}

export default WishlistPage;
