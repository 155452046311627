import React, { Component } from 'react';
import { Link } from "react-router-dom";
import PagePadded from '../components/pagepadded.js';

class Faq extends Component {
	render() {
		return <PagePadded className="faq-page">
			<h1>Frequently Asked Questions</h1>
			<div className="inner">
				<p>Have a question of your own?  Try our <Link to="/contact">contact form</Link>!</p>
				<div className="question">
					<h3>I share an e-mail address with my partner/spouse, but we both want accounts!</h3>
					<p>The family wishlist was build around the concept of e-mail addresses being unique and atomic.  But you have a couple of options if you're using them in another way.</p>
					<ol>
						<li>You could set up another e-mail address and forward it to your own. Your ISP probably allows you a set number of e-mail addresses, and there are a number of free services available 
					- <a href="http://gmail.google.com">Gmail</a>, for example.</li>
						<li>If you're using Gmail, <a href="https://gmail.googleblog.com/2008/03/2-hidden-ways-to-get-more-from-your.html">they support arbitrary + suffixes</a>.  So if your email address was couple@gmail.com, you could sign up with couple+person1@gmail.com and couple+person2@gmail.com, since those would both go to your address anyway.</li>
					</ol>
				</div>

				<div className="question">
					<h3>I have small children who do not have e-mail addresses, what can I do?</h3>
					<p>You can either create e-mail addresses for them (see above) or, 
					since it is unlikely that they will be populating their lists themselves, you could add items for them to your list.  
					That way, you don't have to remember different login information.
					We suggest prepending the child's name to the gift name, or alternatively making a note in the gift notes.</p>
				</div>

				<div className="question">
					<h3>How do I change my password?</h3>
					<p>Once you log in, click on <Link to="/profile">My Profile</Link> in the menu (you must be logged in), or your name where it says <strong>Welcome, [name]</strong>.  The second two input fields will let you update your password.</p>
				</div>

				<div className="question">
					<h3>How do I add a friend?</h3>
					<p>Click on the plus symbol next to <strong>My friends</strong> in the friends list, or <Link to="/add-friend">here</Link> (you must be logged in).  Search for your friend, and click the plus next to their name.</p>
					<p>If your friend wasn't found, click <strong>Send an invite</strong> to invite them.</p>

				</div>

				<div className="question">
					<h3>How do I log in?</h3>
					<p>There is a log in form at the top of <Link to="/">the home page</Link>.</p>
				</div>
			</div>
		</PagePadded>;
	}
}

export default Faq;
