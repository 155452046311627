import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";
import PagePadded from '../components/pagepadded.js';
import './userbar.scss';

import api from "../api/index.js";

class UserBar extends Component {
	constructor(props) {
		super(props);
		this.loggedOut = this.loggedOut.bind(this);
		this.loggedIn = this.loggedIn.bind(this);
		this.state = {
			name: api.auth.getName(),
		};
	}
	
	loggedOut(data) {
		this.setState({name: null});
	}
	
	loggedIn(data) {
		this.setState({name: data.first_name});
	}
	
	componentDidMount() {
		api.auth.addEventListener('userRetrieved', this.loggedIn);
		api.auth.addEventListener('loggedOut', this.loggedOut);
		api.auth.addEventListener('loggedIn', this.loggedIn);
		this.setState({name: api.auth.getName()});
	}
	
	componentWillUnmount() {
		api.auth.removeEventListener('userRetrieved', this.loggedIn);
		api.auth.removeEventListener('loggedOut', this.loggedOut);
		api.auth.removeEventListener('loggedIn', this.loggedIn);
		
	}

	
	render() {
		if (this.state.name == null) {
			//return <div className="userbar closed"></div>;
			return <div className="userbar">
				<PagePadded>
					<p><Link to="/register">Register now <FontAwesomeIcon icon={faArrowRight}/></Link></p>
				</PagePadded>
			</div>;
			
			
		}
		
		return <div className="userbar">
			<PagePadded>
				<p>Welcome, <Link to="/profile">{this.state.name} <FontAwesomeIcon icon={faUser}/></Link></p>
			</PagePadded>
		</div>;
	}
}

export default UserBar;
