import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'

import api from '../api/index.js';
import Loading from './loading.js';
import './giftform.scss';

class Giftform extends Component {
	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
		this.giftReceived = this.giftReceived.bind(this);
		this.save = this.save.bind(this);
		this.state = {
			name: '',
			notes: '',
			priority: 5,
			link: '',
			edit: this.props.hasOwnProperty('id') && this.props.id != null && this.props.id !== 0
		};
	}
	
	save(event) {
		if (this.state.edit)
			api.wishlist.updateGift(this.props.id, this.state.name, this.state.priority, this.state.link, this.state.notes);
		else
			api.wishlist.addGift(this.state.name, this.state.priority, this.state.link, this.state.notes);
		event.preventDefault();
	}
	
	componentDidMount() {
		api.wishlist.addEventListener('giftRetrieved', this.giftReceived);
		if (this.state.edit) {
			api.wishlist.retrieveGift(this.props.id);
		}
	}

	giftReceived(data) {
		if(data.gift_id === this.props.id)
			this.setState({
				name: data.name,
				notes: data.notes,
				priority: data.priority,
				link: data.link
			});
	}

	componentWillUnmount() {
		api.wishlist.removeEventListener('giftReceived', this.giftReceived);
	}

	handleChange(e) {
		this.setState({[e.target.name]: e.target.value});
	}

	
	
	render() {
		let inner = <Loading />;
		if (!this.state.edit || this.state.name.length > 1) {
			inner = <div>
				<p><input placeholder="Gift name" type="text" name="name" onChange={this.handleChange} value={this.state.name} /></p>
				<p> 
					<select onChange={this.handleChange} name="priority" value={this.state.priority}>
						<option value="10">High priority</option>
						<option value="5">Medium priority</option>
						<option value="0">Low priority</option>
					</select>
				</p>
				<p><input placeholder="HTTPS://" type="text" name="link" onChange={this.handleChange} value={this.state.link} /></p>
				<p><textarea placeholder="notes" name="notes" onChange={this.handleChange} value={this.state.notes} /></p>
				<p><input type="submit" value="Save" />
				<button className="block cancel" onClick={this.props.onCancel}><FontAwesomeIcon icon={faPlusCircle} className="x" />Cancel</button></p>
			</div>;
		}
		return <form onSubmit={this.save} className="giftinterface">
			{inner}
			</form>;
		
	}
}

export default Giftform;
