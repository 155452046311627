import { Component } from 'react';

import api from "../api/index.js";

class IfLoggedIn extends Component {
	constructor(props) {
		super(props);
		this.loggedOut = this.loggedOut.bind(this);
		this.loggedIn = this.loggedIn.bind(this);
		this.state = {
			loggedIn: api.auth.isLoggedIn(),
		};
	}
	
	loggedOut(data) {
		this.setState({loggedIn: false});
	}
	
	loggedIn(data) {
		this.setState({loggedIn: true});
	}
	
	componentDidMount() {
		api.auth.addEventListener('loggedOut', this.loggedOut);
		api.auth.addEventListener('loggedIn', this.loggedIn);
		this.setState({loggedIn: api.auth.isLoggedIn()});
	}
	
	componentWillUnmount() {
		api.auth.removeEventListener('loggedOut', this.loggedOut);
		api.auth.removeEventListener('loggedIn', this.loggedIn);
		
	}

	
	render() {
		if (!this.state.loggedIn) return null;
		return this.props.children;
	}
}

export default IfLoggedIn;
