import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'

import api from '../api/index.js'

import './email.scss';

class Email extends Component {
	constructor(props) {
		super(props);
		this.remove = this.remove.bind(this);
	}

	remove() {
		api.emails.removeEmail(this.props.email);
	}
	

	render() {
		return <div className="email">
			<h4>
				<div className="text">
					{this.props.email}
				</div>
				<div className="button">
					<button className="clear" onClick={this.remove}><FontAwesomeIcon icon={faPlusCircle} /></button>
				</div>
			</h4>
		</div>
	}
}

export default Email;
