import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import { toast } from 'react-toastify';
import Loading from '../components/loading.js';
import PagePadded from '../components/pagepadded.js';
import api from '../api/index.js';

class Register extends Component {
	constructor(props) {
		super(props);
		this.register = this.register.bind(this);
		this.registered = this.registered.bind(this);
		this.registrationFailed = this.registrationFailed.bind(this);
		this.onChange = this.onChange.bind(this);
		this.state = {
			email: '',
			first_name: '',
			last_name: '',
			location: '',
			reference_string: '',
			just_registered: false,
			loading: false
		};
	}
	
	onChange(e) {
		this.setState({[e.target.name]: e.target.value});
	}

	register(event) {
		console.log("Registering...");
		if (this.state.email.includes('@')) {
			api.auth.addEventListener('registered', this.registered);
			api.auth.addEventListener('registrationError', this.registrationFailed);
			this.setState({loading: true});
			api.auth.register(this.state.email, this.state.first_name, this.state.last_name, this.state.location, this.state.reference_string);
		} else 
			toast.error('That doesn\'t appear to be an e-mail address.');
		event.preventDefault();
	}
	

	registered(data) {
		api.auth.removeEventListener('registered', this.registered);
		api.auth.removeEventListener('registrationError', this.registrationFailed);
		
		this.setState({loading: false, just_registered: true});
	}

	registrationFailed(data) {
		api.auth.removeEventListener('registered', this.registered);
		api.auth.removeEventListener('registrationError', this.registrationFailed);
		this.setState({loading: false});
	}
	
	componentWillUnmount() {
		api.auth.removeEventListener('registered', this.registered);
		api.auth.removeEventListener('registrationError', this.registrationFailed);
	}

	
	render() {
		let inner = null;
		if (this.state.loading) {
			inner = <div><p><small>Attempting user registration...</small></p><Loading /></div>;
		} else {
			if (this.state.just_registered) 
				inner = <Redirect to="/" />;
			else
				inner = <div>
					<form onSubmit={this.register}>
						<input type="text" name="email" value={this.state.email} placeholder="Email address" onChange={this.onChange} />
						<input type="text" name="first_name" value={this.state.first_name} placeholder="First name" onChange={this.onChange} />
						<input type="text" name="last_name" value={this.state.last_name} placeholder="Last name" onChange={this.onChange} />
						<input type="text" name="location" value={this.state.location} placeholder="Location" onChange={this.onChange} />
						<input type="text" name="reference_string" value={this.state.reference_string} placeholder="Quote or nickname or other" onChange={this.onChange} />
						<input type="submit" value="Sign me up!" />
					</form></div>;
		}
		return <div className="about-page">
				<PagePadded>
				<h1>Register</h1>
				{inner}
				</PagePadded>
			</div>;
	}
}

export default Register;
