import Dispatcher from './Dispatcher.js';

class SecretSanta extends Dispatcher {
	constructor(parent) {
		super();
		this.parent = parent;
	}
	
	
	send(title, name, description, participants) {
		const url = 'secretsanta';
		this.parent.post(url, {
			title: title,
			name: name,
			description: description,
			participants: participants
		})
			.then(
					(result) => {
						this.call('sent', [result]);
						this.parent.success("Your messages have been sent on their way.");
						
				}
			).catch(this.parent.catcher); 
		
	}

}


export default SecretSanta;
