import Dispatcher from './Dispatcher.js';
import cookies from "react-cookies";

class Auth extends Dispatcher {
	constructor(parent) {
		super();
		this.parent = parent;
		this.sid = cookies.load('PHPSESSID');
		this.firstload = true;
		this.user = null;
	}
	
	getUserProp(prop) {
		if (this.user == null)
			return null;
		return this.user[prop];
	}
	
	softUserProp(prop) {
		let p = this.getUserProp(prop);
		return (p == null) ? '' : p;
	}
	
	getName() { return this.getUserProp('first_name'); }
	
	deleteCookie() {
		cookies.remove('PHPSESSID',{ path: '/' });
	}
	
	userID() { return this.getUserProp('user_id'); }
	
	isCurrentUser(uid) {
		return this.user.user_id != null && this.userID() === uid;
	}
	
	isLoggedIn() {
		if (this.sid == null) {
			return false;
		}
		
		if (this.firstload)
			this.firstload = false;
			this.parent.get('auth')
				.then(
					(result) => {
						this.user = result;
						this.call('userRetrieved', [result]);
				}
			).catch(this.parent.catcher);
		return true;
	}
	
	login(username, password) {
		this.parent.post('auth', {'u': username, 'p': password})
			.then(
				(result) => {
					this.sid = result.sid;
					this.user = result;
					cookies.save('PHPSESSID', this.sid, { path: '/' })
					this.call('loggedIn', [result]);
					this.firstload = false;
					return result;
			}
		).catch(this.parent.catcher);
	}
	
	logout() {
		this.sid = null;
		this.parent.del('auth').then(
			(result) => {
				this.deleteCookie();
				this.call('loggedOut');
				console.log('logged out', [result]);
			}
		).catch(this.parent.catcher);
	}
	
	
	saveProfile(location, reference_string) {
		this.parent.put('users', {'location': location, 'reference_string': reference_string})
			.then(
				(result) => {
					this.call('profileUpdated', [result]);
					this.user.location = location;
					this.user.reference_string = reference_string;
					this.parent.success("Profile updated!");
			}
		).catch(this.parent.catcher);
	}
	
	
	updatePassword(password) {
		this.parent.put('users', {'password': password})
			.then(
				(result) => {
					this.call('passwordUpdated', [result]);
					this.parent.success("Password updated!");
			}
		).catch(this.parent.catcher);
	}
	
	register(email, first_name, last_name, location, reference_string) {
		this.parent.post('users', {'u': email, 'first_name': first_name, 'last_name': last_name, 'location': location, 'reference_string': reference_string})
			.then(
				(result) => {
					this.call('registered', [result]);
					this.parent.success("User registered - check your email for details.");
			}
		).catch((e) => {
				this.call('registrationError', [e]);
				this.parent.catcher(e);
			}
		);
	}
	
	
	resetPassword(key, password) {
		this.parent.post('auth/reset', {'key': key, 'p': password})
			.then(
				(result) => {
					this.call('passwordUpdated', [result]);
					this.parent.success("Your password has been reset!");
			}
		).catch(this.parent.catcher);
	}
	
	forgottenPassword(email) {
		this.parent.post('auth/forgot', {'u': email})
			.then(
				(result) => {
					this.call('forgottenPassword', [result]);
					this.parent.success("A reset link has been emailed to you.");
			}
		).catch(this.parent.catcher);
	}

}


export default Auth;
