import React, { Component } from 'react';
import api from "../api/index.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faWindowClose } from '@fortawesome/free-solid-svg-icons'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import './nav.scss';
import NavItem from './navitem.js';
import IfLoggedIn from './ifloggedin.js';

class Nav extends Component {
	constructor(props) {
		super(props);
		this.loggedOut = this.loggedOut.bind(this);
		this.loggedIn = this.loggedIn.bind(this);
		api.auth.addEventListener('loggedOut', this.loggedOut);
		api.auth.addEventListener('loggedIn', this.loggedIn);
		this.state = {
			loggedIn: false,
			expanded: false
		};
		this.toggleMenu = this.toggleMenu.bind(this);
		this.logout = this.logout.bind(this);
	}

	
	logout(e) {
		api.auth.logout();
		this.toggleMenu(e);
	}
	
	toggleMenu(e) {
		if (this.state.expanded)
			this.props.onNavClose();
		else
			this.props.onNavOpen();
		this.setState({expanded: ~this.state.expanded});
	}

	loggedOut(data) {
		this.setState({loggedIn: false});
	}


	loggedIn(data) {
		this.setState({loggedIn: true});
	}


	componentDidMount() {
		api.auth.isLoggedIn();
	}
	
	componentWillUnmount() {
		api.auth.removeEventListener('loggedOut', this.loggedOut);
		api.auth.removeEventListener('loggedIn', this.loggedIn);
		
	}

	render() {
		let home = this.state.loggedIn ? 'My wishlist' : 'Home';
		let inner = '';
		if (this.state.expanded) {
			inner = (<nav className="expanded" key="open">
						<button className="icon" onClick={this.toggleMenu}><FontAwesomeIcon icon={faWindowClose} /></button>
						<ul>
							<li><NavItem to="/" onClick={this.toggleMenu}>{home}</NavItem></li>
							<li><NavItem to="/about" onClick={this.toggleMenu}>About</NavItem></li>
							<li><NavItem to="/faq" onClick={this.toggleMenu}>FAQ</NavItem></li>
							<li><NavItem to="/contact" onClick={this.toggleMenu}>Contact</NavItem></li>
							<li><NavItem to="/secretsanta" onClick={this.toggleMenu}>Secret Santa</NavItem></li>
							<IfLoggedIn>
								<li><NavItem to="/profile" onClick={this.toggleMenu}>My Profile</NavItem></li>
								<li><NavItem to="/" onClick={this.logout}>Logout</NavItem></li>
							</IfLoggedIn>

						</ul>
					</nav>);
		} else {
			 inner = <nav key="closed">
						<button className="icon" onClick={this.toggleMenu}><FontAwesomeIcon icon={faBars} /></button>
					</nav>;
		}
		
		return <ReactCSSTransitionGroup 
					transitionName="nav"
					transitionEnterTimeout={300}
					transitionLeaveTimeout={300}>
				{inner}
				</ReactCSSTransitionGroup>
	}
}

export default Nav;
