import Dispatcher from './Dispatcher.js';

class Friends extends Dispatcher {
	constructor(parent) {
		super();
		this.parent = parent;
	}
	
	
	retrieve(user = null) {
		let url =  'friends'
		if (user != null)
			url += '/' + user;
		this.parent.get(url)
			.then(
					(result) => {
						this.call('friendsRetrieved', [result]);
				}
			).catch(this.parent.catcher);
	}
	
	search(query) {
		let url =  'friends/search?q=' + encodeURIComponent(query);
		this.parent.get(url)
			.then(
					(result) => {
						this.call('searchResults', [result]);
				}
			).catch(this.parent.catcher);
	}
	
	
	addFriend(id) {
		const url = 'friends/';
		this.parent.post(url, {
			user_id: id
		})
			.then(
					(result) => {
						this.call('friendAdded', [result]);
						this.parent.success("Friend added!");
						this.retrieve();
				}
			).catch(this.parent.catcher); 
	}
	
	
	removeFriend(id) {
		const url = 'friends'
		this.parent.del(url, {email: id})
			.then(
					(result) => {
						this.parent.success("Friend removed!");
						this.call('friendDeleted', [result]);
						this.retrieve();
				}
			).catch(this.parent.catcher); 
	}
	
	addFriendByEmail(email) {
		const url = 'friends/';
		this.parent.post(url, {
			email: email
		})
			.then(
					(result) => {
						this.call('friendAdded', [result]);
						this.parent.success("Invitation sent!  Once they join, they'll automatically appear on your wishlist.");
						
				}
			).catch(this.parent.catcher); 
		
	}

}


export default Friends;
