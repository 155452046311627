import React, { Component } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGift, faCheckSquare, faExternalLinkAlt, faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons'
import Linkify from 'react-linkify';

import PrinterOnly from './printeronly.js';
import PagePadded from './pagepadded.js';
//import Loading from './loading.js';

import api from '../api/index.js';
import './gift.scss';


class Gift extends Component {
	constructor(props) {
		super(props);
		this.giftReceived = this.giftReceived.bind(this);
		this.handleClickEvent = this.handleClickEvent.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleReceived = this.handleReceived.bind(this);
		this.handleReserve = this.handleReserve.bind(this);
		this.state = {
			gift_data: null,
			expanded: false
		};
	}
	
	handleClickEvent(e) {
		if (this.state.expanded === false && this.state.gift_data == null)
			api.wishlist.retrieveGift(this.props.id);
		this.setState({expanded: !this.state.expanded});
	}
	
	componentDidMount() {
		api.wishlist.addEventListener('giftRetrieved', this.giftReceived);
	}

	giftReceived(data) {
		if(data.gift_id === this.props.id)
			this.setState({gift_data: data});
	}

	componentWillUnmount() {
		api.wishlist.removeEventListener('giftRetrieved', this.giftReceived);
	}

	
	handleEdit(e) {
		if (this.props.hasOwnProperty('onEdit'))
			this.props.onEdit(this.state.gift_data.gift_id);
	}
	handleDelete(e) {
		api.wishlist.deleteGift(this.state.gift_data.gift_id);
	}
	handleReceived(e) {
		api.wishlist.receiveGift(this.state.gift_data.gift_id);
	}
	handleReserve(e) {
		if(this.props.status === 'reserved')
			api.wishlist.unreserveGift(this.state.gift_data.gift_id);
		else
			api.wishlist.reserveGift(this.state.gift_data.gift_id);
	}
	
	
	render() {
		let gift_data = this.state.gift_data ? this.state.gift_data : this.props.gift_data;
		let paragraphs = [];
		paragraphs.push(
			<PrinterOnly key="priority-print"><p className="priority">{gift_data.priority > 5 ? 'High' : gift_data.priority > 1 ? 'Medium' : 'Low'} priority</p></PrinterOnly>
		);

		if (gift_data.notes.length > 0)
			paragraphs.push(
				<pre key="notes">
					<Linkify properties={{target: '_blank', rel: 'noopener'}}>
						{gift_data.notes}
					</Linkify>
				</pre>
			);
		if (gift_data.link.length > 10) {
			paragraphs.push(<PrinterOnly invert key="link-screen"><p><a href={gift_data.link}>Go to website <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p></PrinterOnly>);
			paragraphs.push(<PrinterOnly key="link-print"><p><a href={gift_data.link}>{gift_data.link}</a></p></PrinterOnly>);
		}
		
		paragraphs.push(
			<PrinterOnly invert key="priority-screen"><p className="priority">{gift_data.priority > 5 ? 'High' : gift_data.priority > 1 ? 'Medium' : 'Low'} priority</p></PrinterOnly>
		);
		let button = <div className="controls">
				<span><button onClick={this.handleEdit} className="edit block"><FontAwesomeIcon icon={faEdit} />Edit</button>
				<button  onClick={this.handleDelete} className="delete block"><FontAwesomeIcon icon={faTrashAlt} />Delete</button></span>
				<button  onClick={this.handleReceived} className="received block"><FontAwesomeIcon icon={faGift} />Mark as Received</button>
			</div>;
		if (!api.auth.isCurrentUser(gift_data.user_id)) {
			const title = (this.props.status === 'reserved') ? "Unreserve" : "Reserve";
		button = <div className="controls"><button  onClick={this.handleReserve} className={title + "  block"}><FontAwesomeIcon icon={faGift} />{title}</button></div>;
		}

		
		let inner = <PagePadded key={"giftinfo" + gift_data.gift_id} className={"expanded full-page " + (this.state.expanded ? "open": "closed")}>
				{paragraphs}
				{button}
			</PagePadded>;
		
		
		let icon = (this.props.status === 'reserved') ?
			<FontAwesomeIcon icon={faCheckSquare} alt="Reserved" title="Reserved"/> :
			<FontAwesomeIcon icon={faGift} />;
			
		
		let recipient = '';
		if (this.props.hasOwnProperty('recipient') && this.props.recipient != null && this.props.recipient.length > 0)
			recipient = <small className="recipient"><PrinterOnly>FOR </PrinterOnly>{this.props.recipient}</small>;
		
		return <div className="gift">
				<h3><button onClick={this.handleClickEvent} className={"clear priority" + this.props.priority}>
					{icon}
					{this.props.name}{recipient}
				</button></h3>
				<ReactCSSTransitionGroup 
					transitionName="giftinfo"
					transitionEnterTimeout={300}
					transitionLeaveTimeout={300}>
					{inner}
				</ReactCSSTransitionGroup>
			</div>
		
	}
}

export default Gift;
