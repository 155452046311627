import React, { Component } from 'react';
import PagePadded from '../components/pagepadded.js';
import api from '../api/index.js';
import { toast } from 'react-toastify';
import { Redirect } from "react-router-dom";


class ResetPassword extends Component {
	constructor(props) {
		super(props);
		this.savePassword = this.savePassword.bind(this);
		this.saved = this.saved.bind(this);
		this.onChange = this.onChange.bind(this);
		this.state = {
			p1: '',
			p2: '',
			done: false
		};
	}


	onChange(e) {
		this.setState({[e.target.name]: e.target.value});
	}
	
	saved(data) {
		this.setState({done: true});
	}
	
	savePassword(event) {
		console.log("Resetting password...");
		event.preventDefault();
		if (this.state.p1 !== this.state.p2) {
			toast.error("Your passwords don't match.");
			return;
		}
		
		if (this.state.p1.length < 3) {
			toast.error("That password is too short.");
			return;
		}
		api.auth.addEventListener('passwordUpdated', this.saved);
		api.auth.resetPassword(this.props.match.params.id, this.state.p1);
	}
	
	componentWillUnmount() {
		api.auth.removeEventListener('passwordUpdated', this.saved);
	}

	
	render() {
		if (this.state.done) 
			return <Redirect to="/" />;
		return <PagePadded>
				<h1>Update your password</h1>
				<form onSubmit={this.savePassword}>
					<input type="password" name="p1" value={this.state.p1} placeholder="Password" onChange={this.onChange} />
					<input type="password" name="p2" value={this.state.p2} placeholder="Password" onChange={this.onChange} />
					<input type="submit" value="Update your password" />
				</form>
			</PagePadded>
	}
}

export default ResetPassword;
