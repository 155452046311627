import React, { Component } from 'react';
import './printeronly.scss';

class PrinterOnly extends Component {
	render() {
		return <span className={this.props.hasOwnProperty('invert') ? "Screen-Only" : "Printer-Only"}>{this.props.children}</span>;
	}
}

export default PrinterOnly;
