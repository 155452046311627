import React, { Component } from 'react';
import api from '../api/index.js';
import Gift from './gift.js';
import './wishlist.scss';
import Loading from './loading.js';


class Wishlist extends Component {
	constructor(props) {
		super(props);
		this.wishlistReceived = this.wishlistReceived.bind(this);
		this.refresh = this.refresh.bind(this);
		this.state = {
			gifts: [],
			loaded: false
		};
	}

	componentDidMount() {
		api.wishlist.addEventListener('wishlistReceived', this.wishlistReceived);
		api.wishlist.retrieve(this.props.id);
		if(this.props.id != null) {
			api.wishlist.addEventListener('giftUnreserved', this.refresh);
			api.wishlist.addEventListener('giftReserved', this.refresh);
		}
	}

	wishlistReceived(data) {
		let d = data.filter(function(a) {
			return (a.status !== 'reserved' || a.reserved_by === api.auth.userID());
		});
		this.setState({gifts: d, loaded: true});
	}
	
	refresh() {
		api.wishlist.retrieve(this.props.id);
	}

	componentWillUnmount() {
		api.wishlist.removeEventListener('wishlistReceived', this.wishlistReceived);
		if(this.props.id != null) {
			api.wishlist.removeEventListener('giftUnreserved', this.refresh);
			api.wishlist.removeEventListener('giftReserved', this.refresh);
		}
	}

	
	
	render() {
		let o = this;
		let list = this.state.gifts.map(function(a) {
			return <Gift name={a.name} status={a.status} id={a.gift_id} gift_data={a} key={"gift" + a.gift_id} priority={a.priority} onEdit={o.props.onEdit} />;
		});

		if (!this.state.loaded)
			list = <Loading />;
		return <div className="wishlist">
				{list}
			</div>;
		
	}
}

export default Wishlist;
