import React, { Component } from 'react';
import Login from '../components/login.js';
import PagePadded from '../components/pagepadded.js';

class HomePage extends Component {
	render() {
		return <div className="Homepage">
				<PagePadded>
					<Login />
				</PagePadded>
				<hr />
				<PagePadded>
					<h1>News</h1>
					<h4>Nov. 17, 2018</h4>
					<p>People use phones now! After a completely rewrite, The Family Wishlist should finally be mobile-friendly.</p>
					<h4>Nov. 27, 2010</h4>
					<p>Added a secret santa tool!</p>

					<h4>Oct. 25, 2010</h4>
					<p>Doing some minor content updates.</p>
				</PagePadded>
				<hr />
				<PagePadded>
					<h1>Welcome to The Family Wishlist</h1>
					<p>So many gifts, and such crowded malls! Gift-giving can be stressful, but with The Family Wishlist organizing who'll get what and for whom sorts itself out for you. Out of date wish-lists, and duplicate purchases are thing of the past!</p>
		
					<p>When might The Family Wishlist be useful to you? Anniversaries, baby showers, weddings, birthdays, Christmas - pretty much any time you're expected to give or receive a gift!</p>
	 
					<p>Sign up now, and protect your friends and family from those embarrassing mistakes! <br />
					Sign up now, and give a gift that will be truly appreciated — and better, be the only on to give it.</p>
	 
					<p><em>Efficient holidays,</em><br />
					- The Family Wishlist</p>
				</PagePadded>
			</div>
		
	}
}

export default HomePage;
