import React, { Component } from 'react';
import PagePadded from '../components/pagepadded.js';
import api from '../api/index.js';


class ResetPassword extends Component {
	constructor(props) {
		super(props);
		this.confirmed = this.confirmed.bind(this);
		this.state = {
			done: false
		};
	}


	
	confirmed(data) {
		this.setState({done: true});
	}
	
	componentDidMount() {
		api.emails.addEventListener('confirmed', this.confirmed);
		api.emails.confirmEmail(this.props.match.params.id);
	}
	
	componentWillUnmount() {
		api.emails.removeEventListener('confirmed', this.confirmed);
	}

	
	render() {
		if (this.state.done) 
			return <PagePadded>
				<h1>Email verification</h1>
				<p>Your email address has been successfully linked to your account.  Thanks!</p>
			</PagePadded>
		
		return <PagePadded>
				<h1>Email verification</h1>
				<p>Verifying your email address...</p>
			</PagePadded>
	}
}

export default ResetPassword;
