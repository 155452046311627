import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import './navitem.scss';

class NavItem extends Component {
	render() {
		return <Link to={this.props.to} onClick={this.props.onClick} className="navitem"><span>
				<FontAwesomeIcon icon={faAngleRight} className="angle"/>
				<FontAwesomeIcon icon={faArrowRight} className="arrow" />
				{this.props.children}</span>
			</Link>;
	}
}

export default NavItem;
